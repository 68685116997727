import React, { useEffect, useState } from 'react';
import { Button, Table, Modal, Row, Col, message, Select, Spin, Divider, DatePicker } from 'antd';
import '../assets/styles/auto-bot.css';
import { EyeOutlined, UserOutlined, RobotOutlined } from '@ant-design/icons';
import { GetBotList, GetConversationMessages, GetDashboardData, GetCallStatus } from '../api/AutoBot';
import moment from 'moment';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { RiFileExcel2Line } from 'react-icons/ri';



const { Option } = Select;
const { RangePicker } = DatePicker;


const statusStyles = {
    pending: {
        backgroundColor: '#ffeb3b',
        borderColor: '#ffeb3b',
        color: '#000',
        
    },
    Pass: {
        backgroundColor: '#bff592',
        borderColor: '#72f361',
        color: '#0aa712',
    },
    Fail: {
        backgroundColor: 'tomato',
        borderColor: 'red',
        color: 'white',
    },
};



const SearchCallLogs = () => {

    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');
    const [workflowOptions, setWorkflowOptions] = useState([]);
    const [dashboardData, setDashboardData] = useState([]);
    const [chatData, setChatData] = useState([]);
    const [isChatModalVisible, setIsChatModalVisible] = useState(false);
    const [fromOptions, setFromOptions] = useState([]);
    const [toOptions, setToOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await GetBotList();
                const tempList = response?.botList || [];  // Ensure tempList is an array

                if (Array.isArray(tempList)) {
                    const options = tempList.map(item => (
                        <Option key={item.WorkFlowId} value={item.WorkFlowId}>
                            {item.WorkFlowName}
                        </Option>
                    ));
                    setWorkflowOptions(options);
                } else {
                    console.error('tempList is not an array:', tempList);
                }
            } catch (error) {
                console.error('Error fetching bot list:', error);
            }
        }
        async function fetchNumberList() {
            try {
                const data = await GetBotList(); // Fetch number list from API

                // Ensure the data has a numberList and it's an array
                if (data && Array.isArray(data.numberList)) {
                    const numberList = data.numberList;
                    const fromNumbers = numberList.filter(num => num.type === 'from');
                    const toNumbers = numberList.filter(num => num.type === 'to');

                    setFromOptions(fromNumbers.map(num => (
                        <Option key={num.phone} value={num.phone}>{num.name}</Option>
                    )));

                    setToOptions(toNumbers.map(num => (
                        <Option key={num.phone} value={num.phone}>{num.name}</Option>
                    )));
                } else {
                    console.error('numberList is not an array or is missing.');
                }
            } catch (error) {
                console.error('Error fetching number list:', error);
            }
        }



        fetchData();
        fetchNumberList();
        fetchDashboardData();
    }, [fromValue, toValue]);

    const fetchDashboardData = async () => {
        try {
            setLoading(true);
            const response = await GetDashboardData(fromDate, toDate);
            setDashboardData(response);
            setCurrentPage(1);
        } catch (error) {
            message.error('Error fetching dashboard data');
        } finally {
            setLoading(false);
        }
    };
    const handleDateChange = (dates) => {
        if (dates) {
            setFromDate(dates[0].format('YYYY-MM-DD'));
            setToDate(dates[1].format('YYYY-MM-DD'));
        }
    };

    const handleView = async (record) => {
        const internalConversationId = record['Internal Conversation ID'];

        // Start fetching chat data immediately
        await fetchChatData(internalConversationId);

        // Start the live chat loop
        const id = setInterval(async () => {
            await fetchChatData(internalConversationId);
        }, 15000); // Fetch every 15 seconds

        setIntervalId(id);
        setIsChatModalVisible(true);
    }; const fetchChatData = async (internalConversationId) => {
        try {
            const conversationMessages = await GetConversationMessages(internalConversationId);
            if (Array.isArray(conversationMessages)) {
                setChatData(conversationMessages);
            } else {
                setChatData([conversationMessages]);
            }
        } catch (error) {
            console.error('Error fetching conversation messages:', error);
        }
    }; const renderChatMessages = () => {
        return chatData.map((message, index) => {
            const isReceived = message.type === 'received';

            return (
                <div
                    key={index}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: isReceived ? 'flex-start' : 'flex-end',
                        marginBottom: '10px',
                        position: 'relative'
                    }}
                >
                    {isReceived &&
                        <div style={{ marginRight: '8px' }}>
                            <RobotOutlined style={{ fontSize: '24px', borderRadius: '50%', backgroundColor: 'lightblue', padding: '4px', border: '2px solid #ccc' }} />
                        </div>
                    }

                    <div
                        style={{
                            position: 'relative',
                            background: isReceived ? '#f0f0f0' : 'lightblue',
                            padding: '10px',
                            borderRadius: '15px',
                            maxWidth: '60%',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        }}
                    >
                        {message.message}
                        <div style={{
                            fontSize: '12px',
                            color: '#888',
                            position: 'absolute',
                            bottom: '-18px',
                            right: '10px'
                        }}>
                            {message.timestamp}
                        </div>
                    </div>

                    {!isReceived &&
                        <div style={{ marginLeft: '8px' }}>
                            <UserOutlined style={{ fontSize: '24px', borderRadius: '50%', backgroundColor: '#f0f0f0', padding: '4px', border: '2px solid #ccc' }} />
                        </div>
                    }
                </div>
            );
        });
    };




    const columnLogs = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            hidden: 'true'
        },
        {
            title: 'Practice Name',
            dataIndex: 'Practice Name',
            key: 'practiceName',

        },
        {
            title: 'Internal Conversation ID',
            dataIndex: 'Internal Conversation ID',
            key: 'Internal ConversationID',
        },
        {
            title: 'From Phone',
            dataIndex: 'From Phone',
            key: 'fromPhone',
        },
        {
            title: 'To Phone',
            dataIndex: 'To Phone',
            key: 'toPhone',
        },
        {
            title: 'Call ID',
            dataIndex: 'Call ID',
            key: 'callId',
            hidden: 'true'
        },
        {
            title: 'Created At',
            dataIndex: 'Created At',
            key: 'createdAt',
            render: text => moment(text).format('DD MMM YYYY hh:mm A'),
        },
        {
            title: 'WorkFlow Name',
            dataIndex: 'Work Flow Name',
            key: 'Work Flow Name',

        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (text) => {
                const style = statusStyles[text] || {}; // Default to an empty object if no status matches
                return (
                    <Button style={style}>
                        {text}
                    </Button>
                );
            }
        },
    
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div>
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() => handleView(record)}
                        style={{ marginRight: 8 }}
                    />

                </div>
            ),
        }

    ];
    return (

        <div className='auto-bot-list' style={{ padding: '20px' }}>
            <Row gutter={16} justify="center">
                <Col>
                    <RangePicker
                        defaultValue={[dayjs(), dayjs()]}
                        onChange={handleDateChange}
                        format="YYYY-MM-DD"
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        onClick={fetchDashboardData}
                        disabled={loading}
                    >
                        {loading ? <Spin /> : 'Fetch Data'}
                    </Button>
                </Col>
                <Col>
                    <CSVLink
                        data={dashboardData}
                        filename={`dashboard_data_${new Date().toISOString().slice(0, 10)}.csv`}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button icon={<RiFileExcel2Line />} type="default" style={{ marginLeft: '8px' }}>
                        </Button>
                    </CSVLink>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Table
                        columns={columnLogs}
                        dataSource={dashboardData}
                        pagination={{
                            current: currentPage, 
                            pageSize: 5,
                            showSizeChanger: false,
                            onChange: (page) => setCurrentPage(page),
                        }}

                        loading={loading}
                        rowKey="id"
                    />
                </Col>
            </Row>
            <Modal
                title={<span style={{ fontSize: '20px', color: 'black', padding: '1px', backgroundColor: 'white', borderRadius: '10px', }}>Conversation Messages</span>}
                visible={isChatModalVisible}
                onCancel={() => setIsChatModalVisible(false)}
                footer={null}
                width={700}
                style={{ borderRadius: '15px', overflow: 'hidden' }}
                centered
            ><Divider />
                <div
                    style={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                        padding: '10px',
                        background: '#fafafa',
                    }}
                >
                    {renderChatMessages()}
                </div>
            </Modal>
        </div>

    );
};

export default SearchCallLogs;
